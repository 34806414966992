import { v4 as uuidv4 } from 'uuid';

const BASE_URL = process.env.REACT_APP_API_URL;
const URL = BASE_URL + 'audio/productionHighlight/';

const productionHighlightList = {
    productionHighlight: [
{
            id: uuidv4(),
            title: 'Baltic Balkan - Wedding Hangover Day 1',
            track: URL + 'BB weddeing hangover day1.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche x Zalvarinis',
            track: URL + '1._Pete_Roche_x_Zalvarinis.mp3'
        },
        {
            id: uuidv4(),
            title: 'Austeja - Beyond HH',
            track: URL + '2._Austeja_-_Beyond_HH.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche x GinGas',
            track: URL + '3._Pete_Roche_x_GinGas.mp3'
        },
        {
            id: uuidv4(),
            title: 'Arandevu - 40 Dienu',
            track: URL + '4._Arandevu_-_40_Dienu.mp3'
        },
        {
            id: uuidv4(),
            title: 'Baltic Balkan - Bazaar',
            track: URL + '5._Baltic_Balkan_-_Bazaar.mp3'
        },
        {
            id: uuidv4(),
            title: 'Firefly - We Can Get There',
            track: URL + '6._Firefly_-_We_Can_Get_There.mp3'
        },
        {
            id: uuidv4(),
            title: `Tom Bones - Don't Mess With Me`,
            track: URL + '7._Tom_Bones_-_Do_not_Mess_With_Me.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche - Sleeping Machine',
            track: URL + '8._Pete_Roche_-_Sleeping_Machine.mp3'
        },
        {
            id: uuidv4(),
            title: `Pete Roche - PB3`,
            track: URL + '9._Pete_Roche_-_PB3.mp3'
        },
        {
            id: uuidv4(),
            title: `Firefly - It is Christmas Time`,
            track: URL + '10._Firefly_-_It_is_Christmas_Time.mp3'
        },
        {
            id: uuidv4(),
            title: `Firefly, Papuga, Torres, Gabrea - Not Alone`,
            track: URL + '11._Firefly,_Papuga,_Torres,_Gabrea_-_Not_Alone.mp3'
        },
        {
            id: uuidv4(),
            title: `Firefly - Rock This Santa`,
            track: URL + '12._Firefly_-_Rock_This_Santa.mp3'
        },
        {
            id: uuidv4(),
            title: `Firefly - Merry Christmas`,
            track: URL + '13._Firefly_-_Merry_Christmas.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche - Acid Ruuidv4()in',
            track: URL + '14._Pete_Roche_-_Acid_Rain.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Papuga x Torres - Find Me',
            track: URL + '15._Papuga_x_Torres_-_Find_Me.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Rain Spectrum',
            track: URL + '16._Rain_Spectrum.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Nevera feat Pete Roche - Eliksyras',
            track: URL + '17._Nevera_feat_Pete_Roche_-_Eliksyras.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Baltic Balkan - Bum Pau',
            track: URL + '18._Baltic_Balkan_-_Bum_Pau.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Papuga - BTM',
            track: URL + '19._Papuga_-_BTM.mp3'
        }
        ,
        {
            id: uuidv4(),
            title: 'Pete Roche - Brighter Days',
            track: URL + '20._Pete_Roche_-_Brighter_Days.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche - Moose Town',
            track: URL + '21._Pete_Roche_-_Moose_Town.mp3'
        },
        {
            id: uuidv4(),
            title: 'Pete Roche - Nothingness',
            track: URL + '22._Pete_Roche_-_Nothingness.mp3'
        },
        {
            id: uuidv4(),
            title: 'Afrodelic - ITara Fan Fan (Pete Roche remix)',
            track: URL + '23._Afrodelic_-_ITara_Fan_Fan_(Pete_Roche_remix).mp3'
        }
    ]

}
export default productionHighlightList;
