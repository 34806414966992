const bioList = {
    bio: [
        {
            id: '1',
            description: `Vytautas Petrošius is a music producer known for his passion for electronic, techno, ambient, and experimental music. He began his musical journey in 2006 with his solo project Papuga, producing drumnbass that gained popularity on Lithuanian radio stations. Over the years, Pete Roche's musical style has evolved, encompassing genres like dubstep, UK house, techno, and pop.`
        }, {
            id: '2',
            description: `Collaboration has been a key element of Vytautas's career. Under the Papuga brand, he has worked with various artists and producers from around the world, resulting in collaborations released on the Bulgarian label "Atom Music Audio," known for its synch deals with major names like BBC and others. In 2009-2014, Vytautas was part of the drumnbass and dubstep duo M-Theory, alongside Tom Bones. M-Theory gained recognition as one of the prominent dubstep acts in Lithuania, with their music being played on radio stations and featured in renowned clubs. They delivered energetic performances that thrilled audiences at parties, clubs, and festivals.`
        }, {
            id: '3',
            description: `From 2014 to 2017, Vytautas collaborated with Nervas, a respected figure in the Lithuanian drumnbass culture scene. Together, they formed the duo Red Milk and garnered attention from industry professionals such as Diplo, Kastle, and Plastician. Their productions received support on prominent radio stations like BBC Radio1 and Rinse FM. They also released music on Kastle's label, "Symbols."`
        }, {
            id: '4',
            description: `In 2021, Vytautas (Pete Roche) marked a significant milestone with the release of his debut album, "Consciousness." The album presents a conceptual and minimalistic sound that tells the story of a lyrical subject living in a digital matrix, longing for purpose and seeking connections through dance and socializing.`
        }, {
            id: '5',
            description: `Currently, Vytautas works with various artists from Lithuania, producing and recording songs in his studio, Palms' Studio, located in Vilnius. Additionally, he has ventured into original soundtrack compositions and sound design for movies and videogames.`
        }
    ]
}

export default bioList;