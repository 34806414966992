const cv = {
    cvSections: [
        {
            id: '1',
            title: 'MUSIC PRODUCER, COMPOSER, SOUND DESIGNER',
            description: [
                '20 years of experience in Producing, writing and recording music.',
                'Melodic, soulful, pop, dance, 4x4, hip hop, club, bass music, contemporary.',
                'Born in 1988, audio professional based in Vilnius, Lithuania.'
            ]
        },
        {
            id: '2',
            title: 'Currently working with',
            description: [
                'Artists in Pop, House, Alternative and other genres.',
                'Game and Cinema OST, Sound design.',
                'Personal original compositions and other audio content.'
            ]
        },
        {
            id: '3',
            title: 'Available for',
            description: [
                'Compositions, Production and Collaborations in any genre.',
                'OSTs, Sound Design, Foleys.',
                'Let’s connect and talk about your next project'
            ]
        }
    ]
}

export default cv;