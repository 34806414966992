import { v4 as uuidv4 } from 'uuid';

const BASE_URL = process.env.REACT_APP_API_URL;
const URL = BASE_URL + 'audio/tvBrandsCommercials/';

const tvBrandsComertialsList = {
    tvBrandsComertials: [
        {
            id: uuidv4(),
            title: 'AromaGold Theme',
            track: URL + 'AromaGold Theme.mp3'
        },
        {
            id: uuidv4(),
            title: 'Blogiausias Lietuvos Vairuotojas',
            track: URL + 'Blogiausias Lietuvos Vairuotojas.mp3'
        },
        {
            id: uuidv4(),
            title: 'Boso Theme',
            track: URL + 'Boso Theme.mp3'
        },
        {
            id: uuidv4(),
            title: 'Commercial',
            track: URL + 'Commercial.mp3'
        },
        {
            id: uuidv4(),
            title: 'Commercial 2',
            track: URL + 'Commercial 2.mp3'
        },
        {
            id: uuidv4(),
            title: 'Dark Frontiers Logo',
            track: URL + 'Dark Frontiers Logo.mp3'
        },
        {
            id: uuidv4(),
            title: 'Devynake Theme',
            track: URL + 'Devynake Theme.mp3'
        },
        {
            id: uuidv4(),
            title: 'Kitsy Theme',
            track: URL + 'Kitsy Theme.mp3'
        },
        {
            id: uuidv4(),
            title: 'Narvessen Theme',
            track: URL + 'Narvessen Theme.mp3'
        },
        {
            id: uuidv4(),
            title: 'Nauja Legenda',
            track: URL + 'Nauja Legenda.mp3'
        },
        {
            id: uuidv4(),
            title: 'News 1',
            track: URL + 'News 1.mp3'
        },
        {
            id: uuidv4(),
            title: 'News 2',
            track: URL + 'News 2.mp3'
        },
        {
            id: uuidv4(),
            title: 'TV Ad',
            track: URL + 'TV Ad.mp3'
        },
        {
            id: uuidv4(),
            title: 'TV Ad 2',
            track: URL + 'TV Ad 2.mp3'
        },
        {
            id: uuidv4(),
            title: 'TV Ad 3',
            track: URL + 'TV Ad 3.mp3'
        },
        {
            id: uuidv4(),
            title: 'TV Ad 4',
            track: URL + 'TV Ad 4.mp3'
        }
    ]
}
export default tvBrandsComertialsList;
