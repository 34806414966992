const servicesList = {
    services: [
        {
            id: '1',
            description: 'Consultation'
        },
        {
            id: '2',
            description: 'Song or audio file feedback'
        },
        {
            id: '3',
            description: 'Edit audio files'
        },
        {
            id: '4',
            description: 'Final edits, arrangement and production for a song or an audio file'
        },
        {
            id: '5',
            description: 'Instrumental for a song production'
        },
        {
            id: '6',
            description: 'Audio production'
        },
        {
            id: '7',
            description: 'Song production'
        },
        {
            id: '8',
            description: 'Recording/editing/foley'
        },
        {
            id: '9',
            description: 'Commercial ads'
        },
        {
            id: '10',
            description: 'Making SFX/music for a video, movie or game'
        },
        {
            id: '11',
            description: 'Studio rental'
        },
        {
            id: '12',
            description: 'Etc'
        }
    ]
}

export default servicesList;